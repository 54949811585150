import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

interface StyledLogoShowcaseListProps {
  isHalfWidth: boolean;
}

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.gradients.alternate};
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: ${rem('40px')} ${({ theme }) => rem(theme.spacings.m)};
    flex-direction: column;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: ${rem('80px')} 0;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const StyledLogoShowcaseList = styled.div<StyledLogoShowcaseListProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => rem(theme.spacings.m)};

  ${({ isHalfWidth }) =>
    isHalfWidth &&
    css`
      justify-content: flex-start;
      width: 50%;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.l}) {
        width: 100%;
        justify-content: center;
      }
    `}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    justify-content: start;
  }
`;

export const StyledLogoShowcase = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => rgba(theme.colors.main, 0.4)};
  border-radius: ${rem('8px')};
  overflow: hidden;
`;

export const StyledLogoShowcaseHeader = styled.h2`
  background-color: ${({ theme }) => rgba(theme.colors.main, 0.6)};
  font-size: ${rem('14px')};
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin: 0;
  padding: ${({ theme }) =>
    `${rem(theme.spacings.xs)} ${rem(theme.spacings.s)}`};
`;

export const StyledLogoShowcaseContent = styled.div<{ bigImages?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => rem(theme.spacings.l)};
  padding: ${({ theme }) =>
    `${rem(theme.spacings.m)} ${rem(theme.spacings.m)}`};
  & img {
    display: block;
    width: ${({ bigImages }) => (bigImages ? rem('120px') : rem('180px'))};
    height: ${({ bigImages }) => (bigImages ? rem('120px') : rem('70px'))};
    pointer-events: none;
    user-select: none;
  }
`;

export const StyledLogoDescription = styled.div`
  text-align: left;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-left: ${rem('5px')};
    text-align: center;
  }
`;

export const StyledButton = styled.button`
  width: 80%;
  margin-top: ${rem('40px')};
  padding: ${rem('10px')} ${rem('20px')};
  background-color: ${({ theme }) => rgba(theme.colors.main, 0.4)};
  border: 0.03rem solid;
  border-radius: ${rem('8px')};
  cursor: pointer;
  font-size: ${rem('16px')};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-top: ${rem('20px')};
    font-size: ${rem('14px')};
  }
`;

export const StyledLogoItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => rem(theme.spacings.l)};
  gap: ${rem('10px')};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
`;

export const StyledLogoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-wrap: wrap;
  }
`;
