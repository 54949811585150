import { CtaButton, Grid } from '@zappyrent/hundop';
import { upperCase } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledWrapper,
  StyledLogoShowcase,
  StyledLogoShowcaseList,
  StyledLogoDescription,
  StyledLogoShowcaseContent,
  StyledLogoItem,
  StyledLogoRow,
  StyledLogoShowcaseHeader,
} from './PartnerShowcase.styles';

export const newsLogos = [
  {
    href: 'https://cdn-website-assets.s3.eu-central-1.amazonaws.com/assets/logos/octopus-partner-logo.png',
    imageAlt: 'Octopus',
    description: 'product:octopusMessage',
  },
  {
    href: 'https://cdn-website-assets.s3.eu-central-1.amazonaws.com/assets/logos/weTacoooPartnerLogo.png',
    imageAlt: 'We Tacoo',
    description: 'product:wetaccoMessage',
  },
];

interface LogoShowcaseProps {
  halfWidthContent?: boolean;
}
const handlePartnerClick = () => {
  window.open(
    'https://try.zappyrent.com/partnership/',
    '_blank',
    'noopener noreferrer',
  );
};

const PartnerShowcase = ({
  halfWidthContent = false,
}: LogoShowcaseProps): JSX.Element => {
  const { t } = useTranslation();
  const renderDescriptionWithLink = (description: string) => {
    const octoLink = 'https://octopusenergy.it/affiliate/zappyrent';
    const clickHere = t('product:click_here');
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return description.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return part === octoLink ? (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {clickHere}
          </a>
        ) : (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };
  return (
    <StyledWrapper>
      <Grid justifyContent="flex-start" alignItems="center" container>
        <StyledLogoShowcaseList isHalfWidth={halfWidthContent}>
          <StyledLogoShowcase>
            <StyledLogoShowcaseHeader>
              {t('product:zappyrentPartnerships')}
            </StyledLogoShowcaseHeader>
            <StyledLogoShowcaseContent>
              <StyledLogoRow>
                {newsLogos.map((logo, index) => (
                  <StyledLogoItem key={index}>
                    <img src={logo.href} alt={logo.imageAlt} />
                    <StyledLogoDescription>
                      {renderDescriptionWithLink(t(logo.description))}
                    </StyledLogoDescription>
                  </StyledLogoItem>
                ))}
              </StyledLogoRow>
            </StyledLogoShowcaseContent>

            <StyledLogoShowcaseContent>
              <CtaButton
                width="80%"
                onClick={handlePartnerClick}
                user="landlord"
              >
                {upperCase(t('product:becomePartner'))}
              </CtaButton>
            </StyledLogoShowcaseContent>
          </StyledLogoShowcase>
        </StyledLogoShowcaseList>
      </Grid>
    </StyledWrapper>
  );
};

export default PartnerShowcase;
